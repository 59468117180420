<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      'Activation › Balance': 'Ativação › Saldo',
      'Order Id': 'Pedido Id',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Status',
      'Payment': 'Pagamento',
      'Details': 'Detalhes',
      'Pending': 'Pendente',
      'Approved': 'Aprovado',
      'Canceled': 'Cancelado',
      'View': 'Ver',
      'Value': 'Valor',
      'Balance': 'Saldo',
      'Buy Balance': 'Comprar Saldo',
      'The minimum value is R$ 100,00.': 'O valor mínimo é de R$ 50,00.',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Activation › Balance': 'Activación › Equilibrio',
      'Order Id': 'Pedido Id',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Estado',
      'Payment': 'Pago',
      'Details': 'Detalles',
      'Pending': 'Pendiente',
      'Approved': 'Aprobado',
      'Canceled': 'Cancelado',
      'View': 'Ver',
      'Value': 'Valor',
      'Balance': 'Equilibrio',
      'Buy Balance': 'Comprar Equilibrio',
      'The minimum value is R$ 100,00.': 'El valor mínimo es de R$ 50,00.',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },

      table: {
        heade: [
          'Order Id', 'Date', 'Total', 'Status', 'Payment', 'Details'
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      buy: {
        modal: false,
        alert: {
          type: '',
          message: ''
        },
        value: '0,00'
      }
    };
  },
  directives: { money: VMoney },
  validations: {
    buy: {
      value: { required }
    },
  },
  methods: {
    getList() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('wallet/activation/balance')
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
          } else {
            this.table.body = null
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    },
    buySubmit() {
      this.$v.buy.$touch();

      if (this.buy.value) {
        this.buy.loading = true

        api
          .post('wallet/activation/balance', {
            value: this.buy.value,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.buy.value = '0,00'
              this.$v.buy.$reset()

              this.buy.alert.type = 'alert-success'
              this.buy.alert.message = response.data.message

              this.$router.push('/store/order/'+response.data.order.id);
            } else {
              this.buy.alert.type = 'alert-danger'
              this.buy.alert.message = response.data.message
            }

            this.buy.loading = false
          })
      }
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Activation › Balance') }}</h4>
          <div class="page-title-right">
            <button
              type="button"
              class="btn btn-dark btn-sm text-uppercase"
              @click="buy.modal = true"
            >{{ t('Buy Balance') }}</button>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="buy.modal" :title="t('Buy Balance')" centered>
      <b-form @submit.prevent="buySubmit">
        <div v-if="buy.alert.message" :class="'alert ' + buy.alert.type">{{ t(buy.alert.message) }}</div>
        <b-form-group id="buy-value" :label="t('Value')" label-for="buy-value">
          <b-form-input id="buy-value" v-model="buy.value" v-money="money" type="text" :class="{ 'is-invalid': $v.buy.value.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.buy.value.$error" class="invalid-feedback">
            <span v-if="!$v.buy.value.required">{{ t('Value is required.') }}</span>
          </div>
        </b-form-group>
        <b-button :disabled="!this.buy.value || this.buy.value==='R$ 0,00'" type="submit" variant="dark">{{ t('Buy') }}</b-button>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td v-for="(data,row) in td" :key="row">
                      <div v-if="row === 'id'">
                        <strong>#{{ data }}</strong>
                      </div>
                      <div v-else-if="row === 'status'">
                        <span v-if="data === '0'" class="badge badge-soft-warning font-size-12">{{ t('Pending') }}</span>
                        <span v-else-if="data === '1'" class="badge badge-soft-success font-size-12">{{ t('Approved') }}</span>
                        <span v-else-if="data === '2'" class="badge badge-soft-danger font-size-12">{{ t('Canceled') }}</span>
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                    <td>
                      <router-link :to="'/store/order/'+td.id" class="btn btn-dark btn-sm btn-rounded">
                        {{ t('View') }}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>